// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/maintenance',
        name: consts.routerNames.maintenance.main,
        meta: {
            //list: 'MaintenanceList',
            mainRouteName: consts.routerNames.maintenance.main,
            hideMap: true,
            pageClass: 'maintenance-page',
            setRoute: {
                maintenance: (route, router, name, maintenanceId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.maintenance.main) {
                        if(this.$isDevelopment) console.warn('maintenance', route);
                        return false;
                    }

                    let routeName =  consts.routerNames.maintenance[name] || consts.routerNames.maintenance.main

                    router.push({
                        name: routeName,
                        params: {
                            maintenanceId,
                        },
                        query: {...route.query}
                    })
                },
                maintenanceFilter: (route, router, filter) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.maintenance.main) {
                        if(this.$isDevelopment) console.warn('maintenance', route);
                        return false;
                    }

                    router.push({
                        name: route.name,
                        params: {
                            ...route.params
                        },
                        query: {...filter}
                    })
                },
                returnToParent: (route, router) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.maintenance.main) {
                        if(this.$isDevelopment) console.warn('returnToParent', route);
                        return false;
                    }

                    let routeName =  route?.meta?.parentRouteName || consts.routerNames.maintenance.main

                    router.push({
                        name: routeName,
                        params: {
                            ...route.params
                        },
                        query: {...route.query}
                    })
                },
            }
        },
        component: () => import('@/views/prometheus/portal/MaintenancePage.vue'),
        children:[
            {
                path: 'history',
                name: consts.routerNames.maintenance.history,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.history,
                },
                component: () => import('@/components/maintenance/history/MaintenanceHistory'),
                children:[
                ],
            },
            {
                path: 'history/:maintenanceId?/create',
                name: consts.routerNames.maintenance.history_create,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.history,
                },
                props: {
                    default: () => ({
                        class: 'maintenance-wizard'
                    })
                },
                components: {
                    default: () => import('@/components/maintenance/history/form/MaintenanceHistoryFormCreate.vue'),
                },
                children:[
                    {
                        path: 'service-tasks',
                        name: consts.routerNames.maintenance.history_create_add_service_task,
                        meta: {
                            mainRouteName: consts.routerNames.maintenance.main,
                            parentRouteName: consts.routerNames.maintenance.history_create,
                            sectionPopupByRoute: {
                                SectionWrapByRoutePopup: {
                                    // class: 'popup-full',
                                    titlePosition: 'center',
                                }
                            }
                        },
                        props: {
                            sectionPopupByRoute: () => ({
                                formClass: 'popup-service-task'
                            })
                        },
                        components: {
                            sectionPopupByRoute: () => import('@/components/maintenance/service-tasks/form/MaintenanceServiceTaskForm.vue'),
                        }
                    },
                ]
            },
            {
                path: 'history/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.history_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.history,
                },
                props: {
                    default: (route) => {
                        return {
                            historyId: route.params.maintenanceId,
                        }
                    }
                },
                components: {
                    default: () => import('@/components/maintenance/history/form/MaintenanceHistoryFormEdit_.vue'),
                },
                children: [
                    {
                        path: 'service-tasks',
                        name: consts.routerNames.maintenance.history_edit_add_service_task,
                        meta: {
                            mainRouteName: consts.routerNames.maintenance.main,
                            parentRouteName: consts.routerNames.maintenance.history_edit,
                            sectionPopupByRoute: {
                                SectionWrapByRoutePopup: {
                                    // class: 'popup-full',
                                    titlePosition: 'center',
                                }
                            }
                        },
                        props: {
                            sectionPopupByRoute: () => ({
                                formClass: 'popup-service-task'
                            })
                        },
                        components: {
                            sectionPopupByRoute: () => import('@/components/maintenance/service-tasks/form/MaintenanceServiceTaskForm.vue'),
                        }
                    },
                ]
            },
            {
                path: 'issues',
                name: consts.routerNames.maintenance.issues,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.issues,
                },
                component: () => import('@/components/maintenance/issues/MaintenanceIssues'),
                children:[
                ],
            },
            {
                path: ':maintenanceId?/edit',
                name: consts.routerNames.maintenance.issues_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.issues,
                },
                props: {
                    default: (roure) => ({
                        issueId: roure.params.maintenanceId,
                    })
                },
                components: {
                    default: () => import('@/components/maintenance/issues/form/MaintenanceIssuesForm.vue'),
                }
            },
            {
                path: 'work-orders',
                name: consts.routerNames.maintenance.work_order,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.work_order,
                },
                component: () => import('@/components/maintenance/work-orders/MaintenanceWorkOrders'),
                children:[
                ],
            },
            {
                path: 'work-orders/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.work_order_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.work_order,
                },
                props: {
                    default: (roure) => ({
                        workOrderId: roure.params.maintenanceId,
                    })
                },
                components: {
                    default: () => import('@/components/maintenance/work-orders/form/MaintenanceWorkOrderForm.vue'),
                }
            },
            {
                path: 'inspection',
                name: consts.routerNames.maintenance.inspections,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.inspections,
                },
                component: () => import('@/components/maintenance/inspections/MaintenanceInspections'),
                children:[
                ],
            },
            {
                path: 'inspection/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.inspections_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.inspections,
                },
                props: {
                    default: (roure) => ({
                        inspectionId: roure.params.maintenanceId,
                    })
                },
                components: {
                    default: () => import('@/components/maintenance/inspections/form/MaintenanceInspectionForm.vue'),
                }
            },
            {
                path: 'service-tasks',
                name: consts.routerNames.maintenance.service_tasks,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.service_tasks,
                },
                component: () => import('@/components/maintenance/service-tasks/MaintenanceServiceTasks'),
                children:[
                ],
            },
            {
                path: 'service-tasks/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.service_tasks_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.service_tasks,
                },
                props: {
                    default: (roure) => ({
                        serviceTaskId: roure.params.maintenanceId,
                    })
                },
                components: {
                    default: () => import('@/components/maintenance/service-tasks/form/MaintenanceServiceTaskForm.vue'),
                }
            },
            {
                path: 'parts',
                name: consts.routerNames.maintenance.parts,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.parts,
                },
                component: () => import('@/components/maintenance/parts/MaintenanceParts'),
                children:[
                ],
            },
            {
                path: 'parts/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.parts_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.parts,
                },
                props: {
                    default: (roure) => ({
                        partId: roure.params.maintenanceId,
                    })
                },
                components: {
                    default: () => import('@/components/maintenance/parts/form/MaintenancePartsForm.vue'),
                }
            },
            {
                path: 'vendors',
                name: consts.routerNames.maintenance.vendors,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.vendors,
                },
                component: () => import('@/components/maintenance/vendors/MaintenanceVendors'),
                children:[
                ],
            },
            {
                path: 'vendors/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.vendors_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.vendors,
                },
                props: {
                    default: (route) => ({vendorId: route.params.maintenanceId,})
                },
                components: {
                    default: () => import('@/components/maintenance/vendors/form/MaintenanceVendorsForm.vue'),
                }
            },
            {
                path: 'contacts',
                name: consts.routerNames.maintenance.contacts,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.contacts,
                },
                component: () => import('@/components/maintenance/contacts/MaintenanceContacts'),
                children:[
                ],
            },
            {
                path: 'contacts/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.contacts_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.contacts,
                },
                props: {
                    default: (route) => ({contactId: route.params.maintenanceId,}),
                },
                components: {
                    default: () => import('@/components/maintenance/contacts/form/MaintenanceContactsForm.vue'),
                },
                children: [
                ]
            },
            {
                path: 'expenses',
                name: consts.routerNames.maintenance.expenses,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.expenses,
                },
                component: () => import('@/components/maintenance/expenses/MaintenanceExpenses'),
                children:[
                ],
            },
            {
                path: 'expenses/:maintenanceId?/edit',
                name: consts.routerNames.maintenance.expenses_edit,
                meta: {
                    mainRouteName: consts.routerNames.maintenance.main,
                    parentRouteName: consts.routerNames.maintenance.expenses,
                },
                props: {
                    default: (route) => ({expensesId: route.params.maintenanceId,})
                },
                components: {
                    default: () => import('@/components/maintenance/expenses/form/MaintenanceExpensesForm.vue'),
                }
            },
        ]
    },
];

export default routes;