// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceContactsFullLoad: false,
        maintenanceContactsLiteLoad: false,
        maintenanceContacts: [],
    },
    actions: {
        fetchMaintenanceContacts/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceContacts', time: Date.now() / 1000})

                const params = getApiProps('maintenance_contacts', args)
                this.$api.maintenancecontacts.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceContacts', inprogress: false})
                    });
            })
        },
        fetchMaintenanceContactsAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceContactsLiteLoad) {
                    return resolve(getters.maintenanceContacts.length)
                }
                this.$api.init.getMaintenanceContacts()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_contacts) {

                            commit('setMaintenanceContacts', response.data.maintenance_contacts)
                            commit('setMaintenanceContactsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceContactsAll', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceContactsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceContactsLiteLoad) {
                    return resolve(getters.maintenanceContacts.length)
                }
                dispatch('fetchMaintenanceContacts', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceContacts', data)
                        commit('setMaintenanceContactsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceContactsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceContactsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceContactsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceContactsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.maintenanceContacts.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let pages = Math.ceil(getters.maintenanceContacts.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceContacts', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceContacts', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceContacts', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceContactsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceContactsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceContactsChanged', inprogress: false})
                })
        },
        fetchMaintenanceContactsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceContactsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceContactsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceContacts', args)
                .then((data) => {
                    commit('updateMaintenanceContacts', data)
                    return dispatch('fetchMaintenanceContacts', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceContacts', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceContactsChanged', inprogress: false})
                });
        },

        saveMaintenanceContact({dispatch}, maintenanceContact) {
            let fn = (maintenanceContact.id) ? 'updateMaintenanceContact' : 'createMaintenanceContact'
            return dispatch(fn, maintenanceContact);
        },
        createMaintenanceContact({commit, dispatch}, maintenanceContact) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contacts')
                this.$api.maintenancecontacts.create(maintenanceContact, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceContact', response.data)
                            dispatch('fetchMaintenanceContactsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceContact({commit, dispatch}, maintenanceContact) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contacts')
                this.$api.maintenancecontacts.update(maintenanceContact.id, maintenanceContact, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceContact', response.data)
                            dispatch('fetchMaintenanceContactsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceContact({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contacts')
                this.$api.maintenancecontacts.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceContact', id)
                            else commit('updateMaintenanceContact', response.data)
                            dispatch('fetchMaintenanceContactsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenanceContact({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contacts')
                this.$api.maintenancecontacts.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceContact', response.data)
                            dispatch('fetchMaintenanceContactsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceContact({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_contacts')
                this.$api.maintenancecontacts.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceContact', response.data)
                            dispatch('fetchMaintenanceContactsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceContactsFullLoad(state, FullLoad) {
            state.maintenanceContactsFullLoad = state.maintenanceContactsFullLoad || FullLoad
        },
        setMaintenanceContactsLiteLoad(state, LitaLoad) {
            state.maintenanceContactsLiteLoad = state.maintenanceContactsLiteLoad || LitaLoad
        },

        setMaintenanceContacts(state, nMaintenanceContacts) {
            state.maintenanceContacts = nMaintenanceContacts
        },

        updateMaintenanceContacts(state, nMaintenanceContacts) {
            if (!state.maintenanceContacts.length) {
                nMaintenanceContacts = nMaintenanceContacts.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceContacts.sort(sortByName)
                state.maintenanceContacts = nMaintenanceContacts
                // const chunks = arraySplitIntoChunks(nMaintenanceContacts)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceContacts.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceContacts.forEach(function (nMaintenanceContact) {
                if (nMaintenanceContact?.name) nMaintenanceContact.name_ = nMaintenanceContact.name.toLocaleLowerCase()
                let i = state.maintenanceContacts.findIndex(u => (u.id == nMaintenanceContact.id))
                if (i < 0) {
                    state.maintenanceContacts.push(nMaintenanceContact) //(Object.freeze(nMaintenanceContact))
                } else
                if (!state.maintenanceContactsFullLoad || state.maintenanceContacts[i][changedField] != nMaintenanceContact[changedField]) {
                    updateObjectByDiff(state.maintenanceContacts[i], nMaintenanceContact)
                    // delete nMaintenanceContact.id
                    // nMaintenanceContact = {...state.maintenanceContacts[i], ...nMaintenanceContact}
                    // state.maintenanceContacts[i] = nMaintenanceContact //Object.freeze(nMaintenanceContact)
                }
            })

        },
        filterMaintenanceContacts(state, nMaintenanceContacts) {
            // let Ids = state.maintenanceContacts.map(u=> u.id)
            let nIds = nMaintenanceContacts.map(u => u.id)
            let removedIds = state.maintenanceContacts.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceContacts.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceContacts.splice(i, 1)
                }
            })
        },
        updateMaintenanceContact(state, nMaintenanceContact) {
            if (nMaintenanceContact?.name) nMaintenanceContact.name_ = nMaintenanceContact.name.toLocaleLowerCase()
            let i = state.maintenanceContacts.findIndex(u => (u.id == nMaintenanceContact.id))
            if (i < 0) {
                state.maintenanceContacts.push(nMaintenanceContact) //(Object.freeze(nMaintenanceContact))
            } else
            if (!state.maintenanceContactsFullLoad || state.maintenanceContacts[i][changedField] != nMaintenanceContact[changedField]) {
                updateObjectByDiff(state.maintenanceContacts[i], nMaintenanceContact)
                // delete nMaintenanceContact.id
                // nMaintenanceContact = {...state.maintenanceContacts[i], ...nMaintenanceContact}
                // state.maintenanceContacts[i] = nMaintenanceContact //Object.freeze(nMaintenanceContact)
            }
        },
        deleteMaintenanceContact(state, id) {
            let i = state.maintenanceContacts.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceContacts.splice(i, 1)
            }
        },

        clearMaintenanceContacts(state) {
            state.maintenanceContacts = []
            state.maintenanceContactsFullLoad = false
        },
    },
    getters: {
        isMaintenanceContactsFullLoad(state) {
            return state.maintenanceContactsFullLoad
        },
        isMaintenanceContactsLiteLoad(state) {
            return state.maintenanceContactsLiteLoad
        },
        maintenanceContacts(state) {
            return state.maintenanceContacts
        },
        maintenanceContactsByIds(state) {
            return state.maintenanceContacts.reduce((maintenanceContactsByIds, maintenanceContact) => {
                maintenanceContactsByIds[maintenanceContact.id] = maintenanceContact
                return maintenanceContactsByIds
            }, {})
        },
        sortedMaintenanceContactsIds(state) {
            let maintenanceContacts = state.maintenanceContacts
            maintenanceContacts.sort(sortByName)
            return maintenanceContacts.map(u => u.id)
        },
    }
}
