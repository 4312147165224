// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenancePartsFullLoad: false,
        maintenancePartsLiteLoad: false,
        maintenanceParts: [],
    },
    actions: {
        fetchMaintenanceParts/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceParts', time: Date.now() / 1000})

                const params = getApiProps('maintenance_parts', args)
                this.$api.maintenanceparts.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceParts', inprogress: false})
                    });
            })
        },
        fetchMaintenancePartsAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenancePartsLiteLoad) {
                    return resolve(getters.maintenanceParts.length)
                }
                this.$api.init.getMaintenanceParts()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_parts) {

                            commit('setMaintenanceParts', response.data.maintenance_parts)
                            commit('setMaintenancePartsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenancePartsAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenancePartsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenancePartsLiteLoad) {
                    return resolve(getters.maintenanceParts.length)
                }
                dispatch('fetchMaintenanceParts', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceParts', data)
                        commit('setMaintenancePartsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenancePartsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenancePartsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenancePartsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenancePartsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.maintenanceParts.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let pages = Math.ceil(getters.maintenanceParts.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceParts', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceParts', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceParts', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenancePartsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenancePartsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenancePartsChanged', inprogress: false})
                })
        },
        fetchMaintenancePartsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenancePartsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenancePartsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceParts', args)
                .then((data) => {
                    commit('updateMaintenanceParts', data)
                    return dispatch('fetchMaintenanceParts', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceParts', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenancePartsChanged', inprogress: false})
                });
        },

        saveMaintenancePart({dispatch}, maintenancePart) {
            let fn = (maintenancePart.id) ? 'updateMaintenancePart' : 'createMaintenancePart'
            return dispatch(fn, maintenancePart);
        },
        createMaintenancePart({commit, dispatch}, maintenancePart) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_parts')
                this.$api.maintenanceparts.create(maintenancePart, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenancePart', response.data)
                            dispatch('fetchMaintenancePartsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenancePart({commit, dispatch}, maintenancePart) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_parts')
                this.$api.maintenanceparts.update(maintenancePart.id, maintenancePart, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenancePart', response.data)
                            dispatch('fetchMaintenancePartsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenancePart({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_parts')
                this.$api.maintenanceparts.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenancePart', id)
                            else commit('updateMaintenancePart', response.data)
                            dispatch('fetchMaintenancePartsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenancePart({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_parts')
                this.$api.maintenanceparts.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenancePart', response.data)
                            dispatch('fetchMaintenancePartsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenancePart({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_parts')
                this.$api.maintenanceparts.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenancePart', response.data)
                            dispatch('fetchMaintenancePartsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenancePartsFullLoad(state, FullLoad) {
            state.maintenancePartsFullLoad = state.maintenancePartsFullLoad || FullLoad
        },
        setMaintenancePartsLiteLoad(state, LitaLoad) {
            state.maintenancePartsLiteLoad = state.maintenancePartsLiteLoad || LitaLoad
        },

        setMaintenanceParts(state, nMaintenanceParts) {
            state.maintenanceParts = nMaintenanceParts
        },

        updateMaintenanceParts(state, nMaintenanceParts) {
            if (!state.maintenanceParts.length) {
                nMaintenanceParts = nMaintenanceParts.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceParts.sort(sortByName)
                state.maintenanceParts = nMaintenanceParts
                // const chunks = arraySplitIntoChunks(nMaintenanceParts)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceParts.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceParts.forEach(function (nMaintenancePart) {
                if (nMaintenancePart?.name) nMaintenancePart.name_ = nMaintenancePart.name.toLocaleLowerCase()
                let i = state.maintenanceParts.findIndex(u => (u.id == nMaintenancePart.id))
                if (i < 0) {
                    state.maintenanceParts.push(nMaintenancePart) //(Object.freeze(nMaintenancePart))
                } else
                if (!state.maintenancePartsFullLoad || state.maintenanceParts[i][changedField] != nMaintenancePart[changedField]) {
                    updateObjectByDiff(state.maintenanceParts[i], nMaintenancePart)
                    // delete nMaintenancePart.id
                    // nMaintenancePart = {...state.maintenanceParts[i], ...nMaintenancePart}
                    // state.maintenanceParts[i] = nMaintenancePart //Object.freeze(nMaintenancePart)
                }
            })

        },
        filterMaintenanceParts(state, nMaintenanceParts) {
            // let Ids = state.maintenanceParts.map(u=> u.id)
            let nIds = nMaintenanceParts.map(u => u.id)
            let removedIds = state.maintenanceParts.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceParts.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceParts.splice(i, 1)
                }
            })
        },
        updateMaintenancePart(state, nMaintenancePart) {
            if (nMaintenancePart?.name) nMaintenancePart.name_ = nMaintenancePart.name.toLocaleLowerCase()
            let i = state.maintenanceParts.findIndex(u => (u.id == nMaintenancePart.id))
            if (i < 0) {
                state.maintenanceParts.push(nMaintenancePart) //(Object.freeze(nMaintenancePart))
            } else
            if (!state.maintenancePartsFullLoad || state.maintenanceParts[i][changedField] != nMaintenancePart[changedField]) {
                updateObjectByDiff(state.maintenanceParts[i], nMaintenancePart)
                // delete nMaintenancePart.id
                // nMaintenancePart = {...state.maintenanceParts[i], ...nMaintenancePart}
                // state.maintenanceParts[i] = nMaintenancePart //Object.freeze(nMaintenancePart)
            }
        },
        deleteMaintenancePart(state, id) {
            let i = state.maintenanceParts.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceParts.splice(i, 1)
            }
        },

        clearMaintenanceParts(state) {
            state.maintenanceParts = []
            state.maintenancePartsFullLoad = false
        },
    },
    getters: {
        isMaintenancePartsFullLoad(state) {
            return state.maintenancePartsFullLoad
        },
        isMaintenancePartsLiteLoad(state) {
            return state.maintenancePartsLiteLoad
        },
        maintenanceParts(state) {
            return state.maintenanceParts
        },
        maintenancePartsByIds(state) {
            return state.maintenanceParts.reduce((maintenancePartsByIds, maintenancePart) => {
                maintenancePartsByIds[maintenancePart.id] = maintenancePart
                return maintenancePartsByIds
            }, {})
        },
        sortedMaintenancePartsIds(state) {
            let maintenanceParts = state.maintenanceParts
            maintenanceParts.sort(sortByName)
            return maintenanceParts.map(u => u.id)
        },
    }
}
