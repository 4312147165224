// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceIssuesFullLoad: false,
        maintenanceIssuesLiteLoad: false,
        maintenanceIssues: [],
        maintenanceIssuesStatuses: [],
        maintenanceIssuesPriorities: [],
    },
    actions: {
        fetchMaintenanceIssues/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceIssues', time: Date.now() / 1000})

                const params = getApiProps('maintenance_issues', args)
                this.$api.maintenanceissues.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceIssues', inprogress: false})
                    });
            })
        },
        fetchMaintenanceIssuesAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceIssuesLiteLoad) {
                    return resolve(getters.maintenanceIssues.length)
                }
                this.$api.init.getMaintenanceIssues()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_issues) {

                            commit('setMaintenanceIssues', response.data.maintenance_issues)
                            commit('setMaintenanceIssuesLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceIssuesAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceIssuesAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceIssuesLiteLoad) {
                    return resolve(getters.maintenanceIssues.length)
                }
                dispatch('fetchMaintenanceIssues', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceIssues', data)
                        commit('setMaintenanceIssuesLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceIssuesAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceIssuesAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceIssuesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceIssuesChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.maintenanceIssues.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let pages = Math.ceil(getters.maintenanceIssues.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceIssues', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceIssues', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceIssues', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceIssuesFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceIssuesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceIssuesChanged', inprogress: false})
                })
        },
        fetchMaintenanceIssuesChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceIssuesFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceIssuesChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceIssues', args)
                .then((data) => {
                    commit('updateMaintenanceIssues', data)
                    return dispatch('fetchMaintenanceIssues', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceIssues', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceIssuesChanged', inprogress: false})
                });
        },
        fetchMaintenanceIssuesStatuses({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceIssuesStatuses', time: Date.now() / 1000})

                this.$api.maintenanceissues.getStatuses()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceIssuesStatuses', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceIssuesStatuses', inprogress: false})
                    });
            })
        },
        fetchMaintenanceIssuesPriorities({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceIssuesPriorities', time: Date.now() / 1000})

                this.$api.maintenanceissues.getPriorities()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceIssuesPriorities', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceIssuesPriorities', inprogress: false})
                    });
            })
        },

        saveMaintenanceIssue({dispatch}, maintenanceIssue) {
            let fn = (maintenanceIssue.id) ? 'updateMaintenanceIssue' : 'createMaintenanceIssue'
            return dispatch(fn, maintenanceIssue);
        },
        createMaintenanceIssue({commit, dispatch}, maintenanceIssue) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_issues')
                this.$api.maintenanceissues.create(maintenanceIssue, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceIssue', response.data)
                            dispatch('fetchMaintenanceIssuesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceIssue({commit, dispatch}, maintenanceIssue) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_issues')
                this.$api.maintenanceissues.update(maintenanceIssue.id, maintenanceIssue, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceIssue', response.data)
                            dispatch('fetchMaintenanceIssuesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceIssue({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_issues')
                this.$api.maintenanceissues.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceIssue', id)
                            else commit('updateMaintenanceIssue', response.data)
                            dispatch('fetchMaintenanceIssuesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenanceIssue({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_issues')
                this.$api.maintenanceissues.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceIssue', response.data)
                            dispatch('fetchMaintenanceIssuesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceIssue({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_issues')
                this.$api.maintenanceissues.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceIssue', response.data)
                            dispatch('fetchMaintenanceIssuesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceIssuesFullLoad(state, FullLoad) {
            state.maintenanceIssuesFullLoad = state.maintenanceIssuesFullLoad || FullLoad
        },
        setMaintenanceIssuesLiteLoad(state, LitaLoad) {
            state.maintenanceIssuesLiteLoad = state.maintenanceIssuesLiteLoad || LitaLoad
        },

        setMaintenanceIssues(state, nMaintenanceIssues) {
            state.maintenanceIssues = nMaintenanceIssues
        },
        updateMaintenanceIssuesStatuses(state, nMaintenanceIssuesStatuses) {
            if (!state.maintenanceIssuesStatuses.length) {
                state.maintenanceIssuesStatuses = nMaintenanceIssuesStatuses
                return true
            }

            nMaintenanceIssuesStatuses.forEach(function (nMaintenanceIssueStatus) {
                let i = state.maintenanceIssuesStatuses.findIndex(u => (u == nMaintenanceIssueStatus))
                if (i < 0) {
                    state.maintenanceIssuesStatuses.push(nMaintenanceIssueStatus) //(Object.freeze(nMaintenanceIssueStatus))
                } else
                if (!state.maintenanceIssuesFullLoad || state.maintenanceIssuesStatuses[i][changedField] != nMaintenanceIssueStatus[changedField]) {
                    updateObjectByDiff(state.maintenanceIssuesStatuses[i], nMaintenanceIssueStatus)
                    // delete nMaintenanceIssueStatus.id
                    // nMaintenanceIssueStatus = {...state.maintenanceIssues[i], ...nMaintenanceIssueStatus}
                    // state.maintenanceIssues[i] = nMaintenanceIssueStatus //Object.freeze(nMaintenanceIssueStatus)
                }
            })

        },
        updateMaintenanceIssuesPriorities(state, nMaintenanceIssuesPriorites) {
            if (!state.maintenanceIssuesPriorities.length) {
                state.maintenanceIssuesPriorities = nMaintenanceIssuesPriorites
                return true
            }

            nMaintenanceIssuesPriorites.forEach(function (nMaintenanceIssuePriorities) {
                let i = state.maintenanceIssuesPriorities.findIndex(u => (u == nMaintenanceIssuePriorities))
                if (i < 0) {
                    state.maintenanceIssuesPriorities.push(nMaintenanceIssuePriorities) //(Object.freeze(nMaintenanceIssuePriorities))
                } else
                if (!state.maintenanceIssuesFullLoad || state.maintenanceIssuesPriorities[i][changedField] != nMaintenanceIssuePriorities[changedField]) {
                    updateObjectByDiff(state.maintenanceIssuesPriorities[i], nMaintenanceIssuePriorities)
                    // delete nMaintenanceIssuePriorities.id
                    // nMaintenanceIssuePriorities = {...state.maintenanceIssues[i], ...nMaintenanceIssuePriorities}
                    // state.maintenanceIssues[i] = nMaintenanceIssuePriorities //Object.freeze(nMaintenanceIssuePriorities)
                }
            })

        },
        updateMaintenanceIssues(state, nMaintenanceIssues) {
            if (!state.maintenanceIssues.length) {
                nMaintenanceIssues = nMaintenanceIssues.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceIssues.sort(sortByName)
                state.maintenanceIssues = nMaintenanceIssues
                // const chunks = arraySplitIntoChunks(nMaintenanceIssues)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceIssues.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceIssues.forEach(function (nMaintenanceIssue) {
                if (nMaintenanceIssue?.name) nMaintenanceIssue.name_ = nMaintenanceIssue.name.toLocaleLowerCase()
                let i = state.maintenanceIssues.findIndex(u => (u.id == nMaintenanceIssue.id))
                if (i < 0) {
                    state.maintenanceIssues.push(nMaintenanceIssue) //(Object.freeze(nMaintenanceIssue))
                } else
                if (!state.maintenanceIssuesFullLoad || state.maintenanceIssues[i][changedField] != nMaintenanceIssue[changedField]) {
                    updateObjectByDiff(state.maintenanceIssues[i], nMaintenanceIssue)
                    // delete nMaintenanceIssue.id
                    // nMaintenanceIssue = {...state.maintenanceIssues[i], ...nMaintenanceIssue}
                    // state.maintenanceIssues[i] = nMaintenanceIssue //Object.freeze(nMaintenanceIssue)
                }
            })

        },
        filterMaintenanceIssues(state, nMaintenanceIssues) {
            // let Ids = state.maintenanceIssues.map(u=> u.id)
            let nIds = nMaintenanceIssues.map(u => u.id)
            let removedIds = state.maintenanceIssues.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceIssues.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceIssues.splice(i, 1)
                }
            })
        },
        updateMaintenanceIssue(state, nMaintenanceIssue) {
            if (nMaintenanceIssue?.name) nMaintenanceIssue.name_ = nMaintenanceIssue.name.toLocaleLowerCase()
            let i = state.maintenanceIssues.findIndex(u => (u.id == nMaintenanceIssue.id))
            if (i < 0) {
                state.maintenanceIssues.push(nMaintenanceIssue) //(Object.freeze(nMaintenanceIssue))
            } else
            if (!state.maintenanceIssuesFullLoad || state.maintenanceIssues[i][changedField] != nMaintenanceIssue[changedField]) {
                updateObjectByDiff(state.maintenanceIssues[i], nMaintenanceIssue)
                // delete nMaintenanceIssue.id
                // nMaintenanceIssue = {...state.maintenanceIssues[i], ...nMaintenanceIssue}
                // state.maintenanceIssues[i] = nMaintenanceIssue //Object.freeze(nMaintenanceIssue)
            }
        },
        deleteMaintenanceIssue(state, id) {
            let i = state.maintenanceIssues.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceIssues.splice(i, 1)
            }
        },

        clearMaintenanceIssues(state) {
            state.maintenanceIssues = []
            state.maintenanceIssuesFullLoad = false
        },
    },
    getters: {
        getMaintenanceIssuesStatuses(state) {
            return state.maintenanceIssuesStatuses
        },
        getMaintenanceIssuesPriorities(state) {
            return state.maintenanceIssuesPriorities
        },
        isMaintenanceIssuesFullLoad(state) {
            return state.maintenanceIssuesFullLoad
        },
        isMaintenanceIssuesLiteLoad(state) {
            return state.maintenanceIssuesLiteLoad
        },
        maintenanceIssues(state) {
            return state.maintenanceIssues
        },
        maintenanceIssuesByIds(state) {
            return state.maintenanceIssues.reduce((maintenanceIssuesByIds, maintenanceIssue) => {
                maintenanceIssuesByIds[maintenanceIssue.id] = maintenanceIssue
                return maintenanceIssuesByIds
            }, {})
        },
        sortedMaintenanceIssuesIds(state) {
            let maintenanceIssues = state.maintenanceIssues
            maintenanceIssues.sort(sortByName)
            return maintenanceIssues.map(u => u.id)
        },
    }
}
